import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

const ClientsPage = () => (
  <Layout bgClass="clients">

  <h2 className="major">Clients</h2>

  <div className="clients-grid textPage">
    <div className="client-link"><Link to="/clients/gbv/2022_tour">Guided By Voices</Link></div>
    <div className="client-link"><Link to="/clients/metallica/2019_europe_tour">Metallica</Link></div>
    <div className="client-link"><Link to="/clients/kingsofleon/2017_tour">Kings of Leon</Link></div>
    <div className="client-link"><Link to="/clients/weirdal/2019_strings_attached_tour">"Weird" Al Yankovic</Link></div>
    <div className="client-link"><Link to="/clients/failure/2022_tour">Failure</Link></div>
    <div className="client-link"><Link to="/clients/ladyantebellum/2015_tour">Lady Antebellum</Link></div>
    <div className="client-link"><Link to="/clients/georgestrait/2015_tour">George Strait</Link></div>
    <div className="client-link"><Link to="/clients/wrightson/frankenstein">Bernie Wrightson</Link></div>
    <div className="client-link"><Link to="/clients/frazetta/2017_frazetta_kickstarter">Frank Frazetta</Link></div>
  </div>

  </Layout>
)

export default ClientsPage
